function positionUpdated(position) {
  let speed

  if (position.coords.speed) {
    speed = metersPerSecondToKilometersPerHour(position.coords.speed)
  } else {
    speed = '&dash;'
  }

  displaySpeed(speed)
  displayCoordinates(position.coords)
}

function geolocationFailed(error) {
  console.warn(error)
}

function displaySpeed(speed) {
  let displayValue = speed ? speed.toFixed(1) : '&dash;'
  document.getElementById("speed").innerHTML = displayValue
}

function displayCoordinates(coordinates) {
  document.getElementById("coords").innerHTML = `${coordinates.latitude},${coordinates.longitude}`
}

function metersPerSecondToKilometersPerHour(value) {
  return value / 1000 * 3600
}

navigator.geolocation.watchPosition(positionUpdated, geolocationFailed, {enableHighAccuracy: true, timeout: 5000})
